import React from 'react';
import Link from 'gatsby-link';
import './styles.css';

export const LearnMore = ({ imgSrc, copy }) => (
  <div className="learn-more">
    <div className="img-clip">
      <img src={imgSrc} className="employee-image" />
    </div>
    
    <div className="learn-more-content">
      <p>{copy}</p>

      <Link to='/about' className="learn-more-link">
        <div className="learn-more-btn">
          LEARN MORE
          <div
            style={{
              width: 0,
              height: 0,
              marginLeft: '10px',
              borderTop: '6px solid transparent',
              borderLeft: '9px solid #334857',
              borderBottom: '6px solid transparent'}}>
          </div>
        </div>
      </Link>
    </div>
    
  </div>
);
