import React from "react"
import Layout from "../layouts"
import { IndexHeader } from "../components/home-page/index-header"
import { AboutBox } from "../components/home-page/about-box"
import { LearnMore } from "../components/home-page/learn-more"
import { AboutCareers } from "../components/home-page/about-careers"
import { RequestInfo } from "../components/home-page/request-info"
import "./index.css"

const IndexPage = ({ data }) => {
  const content = data.allContentfulHome.edges[0].node

  return (
    <Layout>
      <div className="index-header">
        <IndexHeader
          imgSrc={content.headerImage.file.url}
          copy={content.headerCopy}
        />
        <AboutBox
          imgSrc={content.introImage.file.url}
          copy={content.introCopy.introCopy}
        />
      </div>

      <div style={{ padding: "20px" }}></div>

      <LearnMore
        imgSrc={content.aboutImage.file.url}
        copy={content.aboutIntro.aboutIntro}
      />
      <AboutCareers
        images={[
          content.careersImages[0].file.url,
          content.careersImages[1].file.url,
          content.careersImages[2].file.url,
        ]}
        copy={content.careersCopy}
      />
      <RequestInfo copy={content.contactForm} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query homeContent {
    allContentfulHome {
      edges {
        node {
          headerCopy
          introCopy {
            introCopy
          }
          aboutIntro {
            aboutIntro
          }
          contactForm
          careersCopy

          headerImage {
            file {
              url
            }
          }
          introImage {
            file {
              url
            }
          }
          aboutImage {
            file {
              url
            }
          }
          careersImages {
            file {
              url
            }
          }
          clientLogos {
            file {
              url
            }
          }
        }
      }
    }
  }
`
