import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';

export const RequestInfo = ({ copy }) => {
  const states = ["AK","AL","AR","AZ","CA","CO","CT","DC","DE","FL","GA","GU","HI","IA","ID", "IL","IN","KS","KY","LA","MA","MD","ME","MH","MI","MN","MO","MS","MT","NC","ND","NE","NH","NJ","NM","NV","NY", "OH","OK","OR","PA","PR","PW","RI","SC","SD","TN","TX","UT","VA","VI","VT","WA","WI","WV","WY"];

  return (
    <div className="request-info">
      <div className="request-info-copy">
        <h1 className="request-info-h1">REQUEST INFORMATION</h1>
      
        <p className="request-info-p">
          {copy}
        </p>
      </div>

      <div className="request-info-form">
        <div style={{
          display:'flex',
          flexFlow: 'column',
          height: '100%',
          width: '100%'
        }}>
          <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
            <div style={{display: 'flex', flexFlow: 'column'}}>
              <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexFlow: 'column', width: '49%'}}>
                  <label style={{fontSize: '0.6em'}}>First Name</label>
                  <input type="text" name="first name" style={{marginBottom: '5px', padding: '5px'}} />
                </div>

                <div style={{display: 'flex', flexFlow: 'column', width: '49%'}}>
                  <label style={{fontSize: '0.6em'}}>Last Name</label>
                  <input type="text" name="last name" style={{marginBottom: '5px', padding: '5px'}} />
                </div>
              </div>

              <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexFlow: 'column', width: '49%'}}>
                  <label style={{fontSize: '0.6em'}}>Email</label>
                  <input type="text" name="email" style={{padding: '5px'}} />
                </div>

                <div style={{display: 'flex', flexFlow: 'column', width: '49%'}}>
                  <label style={{fontSize: '0.6em'}}>Telephone</label>
                  <input type="text" name="phone" style={{padding: '5px'}} />
                </div>
              </div>

              <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexFlow: 'column', width: '49%'}}>
                  <label style={{fontSize: '0.6em'}}>City</label>
                  <input type="text" name="city" style={{marginBottom: '5px', padding: '5px'}} />
                </div>

                <div style={{display: 'flex', flexFlow: 'column', width: '49%'}}>
                  <label style={{fontSize: '0.6em'}}>State</label>
                  <input type="text" name="state" style={{marginBottom: '5px', padding: '5px'}} />
                </div>
              </div>
            </div>

            <div style={{marginTop: '10px'}}>
              <label style={{fontSize: '0.6em'}}>Your Message</label>
              <textarea rows="10" name="message" style={{display: 'block', resize: 'none', width: '100%'}} />
            </div>

            <div style={{
              width: '235px',
              height: '30px',
              padding: '15px 40px',
              color: 'white',
              fontFamily: 'FuturaBold',
              backgroundColor: '#334857',
              border: '1px solid white',
              outline: 'none',
              cursor: 'pointer',
              display: 'flex',
              flexflow: 'row',
              alignItems: 'center',
              alignSelf: 'flex-end',
              marginTop: '20px'
            }}>
              <input style={{cursor: 'pointer', background:'none', border: 'none', color: 'white'}} type="submit" value="SEND MESSAGE" />
            </div>
          </form>
        </div>

        <div className="location-info">
          <div className="map-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3275.458711818564!2d-89.99657838444574!3d34.819557584350214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x887ff578bf155555%3A0xb3cb52bba856a8ab!2sMoore+Advanced+Inc.!5e0!3m2!1sen!2sus!4v1532026641460" width="400" height="350" frameBorder="0" style={{border:0}}></iframe>
          </div>

          <div>
            <p style={{fontSize: '0.8em', margin: 0, fontWeight: 'bold'}}>CORPORATE HEADQUARTERS</p>
            <p style={{margin: 0, fontSize: '0.8em', fontFamily: 'FuturaBook'}}>2765 Hwy 51 South, Ste E</p>
            <p style={{margin: 0, fontSize: '0.8em', fontFamily: 'FuturaBook'}}>Hernando, MS 38632</p>

            <p style={{fontSize: '0.8em', margin: 0, marginTop: '20px', fontWeight: 'bold'}}><FontAwesomeIcon icon="phone" /> &nbsp;&nbsp;&nbsp;(901) 273-7964</p>
            <p style={{fontSize: '0.8em', margin: 0, fontWeight: 'bold'}}><FontAwesomeIcon icon="envelope" /> &nbsp;&nbsp;&nbsp;info@mooreadvanced.com</p>
            <p style={{fontSize: '0.8em', margin: 0, fontWeight: 'bold'}}><FontAwesomeIcon icon="home" /> &nbsp;&nbsp;&nbsp;BUSINESS HOURS:</p>
            <span style={{fontSize: '0.8em', fontFamily: 'FuturaBook'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;M - F: 8 am - 6 pm</span>
            <br />
            <span style={{fontSize: '0.8em', fontFamily: 'FuturaBook'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sat: 10 am - 2 pm</span>
          </div>
        </div>
      </div>
    </div>
  );
};
