import React from 'react';
import './styles.css';

export const AboutBox = ({ imgSrc, copy }) => (
  <div className="container-box">
    <div className="wrapper">
      <img src={imgSrc} className="image" />

      <p className="paragraph">
        {copy}
      </p>
    </div>
  </div>
);