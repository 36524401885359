import React from 'react';
import './styles.css';

export const IndexHeader = ({ imgSrc, copy }) => (
  <div style={{ backgroundImage: `url(${imgSrc})` }} className="container">
    <h1 className="index-h1">{copy[0]}</h1>
    <p style={{width: '35%', height: 0, marginBottom: '30px', border: '1px solid white'}}></p>
    
    <p style={{marginBottom: '8px'}}>{copy[1]}</p>
    <p>{copy[2]}</p>

    <p>{copy[3]}</p>

    <p style={{marginBottom: '8px'}}>{copy[4]}</p>
    <p>{copy[5]}</p>
  </div>
);