import React from 'react';
import Link from 'gatsby-link';
import './styles.css';

export const AboutCareers = ({ images, copy }) => (
  <div className="careers">
    <img src={images[0]} className="careers-image" />
    <div className="careers-copy">
      <p className="careers-copy-1">{copy[0]}</p>
      <p className="careers-copy-2">{copy[1]}</p>

      <Link to='/careers' className="careers-link">
        <div className="search-careers-btn">
            LEARN MORE
          <div
            style={{
              width: 0,
              height: 0,
              marginLeft: '10px',
              borderTop: '6px solid transparent',
              borderLeft: '9px solid #e08533',
              borderBottom: '6px solid transparent'}}>
          </div>
        </div>
      </Link>
    </div>

    <img src={images[1]} className="careers-image" />
    <img src={images[2]} className="careers-image" />
  </div>
);